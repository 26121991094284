import logo2 from './images/Logo-2/Logo-2.png'
import profile from './images/noun_profile_2068277.svg';
import cart from './images/shopping-cart.svg';
import './Header.css'

export interface HeaderProps {
    changePath: (x: string) => void
}
 
const Header: React.FC<HeaderProps> = ({changePath}) => {
    return (  
        <header className="Header">
            <img src={logo2} className="hashedinLogo" alt="logo" onClick={() => changePath("Dashboard")}/>
            <p className="course" onClick={() => changePath("Dashboard")} title="course">COURSES</p>
            <p className="wish" title="wish">MY WISHLIST</p>
            <img src={cart} alt="cart" className="cart" onClick={() => changePath("cart")}/>
            <img src={profile} alt="profile" className="profile" />
        </header>
    );
}
 
export default Header;