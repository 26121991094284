import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Dashboard from './Dashboard';
import Header from './Header';
import { courseDetails, data } from './Data';
import Cart from './Cart';

const App: React.FC = () => {
  const [courses, setCourses] = useState<courseDetails[]>(data);
  const [cart, setCart] = useState<number[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [searchBool, setSearchBool] = useState<boolean>(false);
  const [searchCourses, setSearchCourses] = useState<courseDetails[]>(data);
  const [sortBool, setSortBool] = useState<boolean>(false);
  const [sortCourses, setSortCourses] = useState<courseDetails[]>(data);
  const [path, setPath] = useState<string>('Dashboard');
  const [baseAddress, setBaseAddress] = useState<number>(0);
  const [offset, setOffset] = useState<number>(2);
  const [priority, setPriority] = useState<number>(0);
  const [disp, setDisp] = useState<boolean>(false);
    
  const changePage = (x: number) => {
      if(x+baseAddress>=0){
        setBaseAddress(x+baseAddress)
      }
      else {
        console.log("reached extreme lower limits");
      }
  }
  
  // Function for adding a course to cart
  // and also calculate the price of items in cart 
  const addCart = (id: number | never) => {
    if((cart.length>0) && (cart.includes(id))) {
      console.log("Already added to cart");
      console.log(cart);
    }
    else if (cart.length==0){
      setCart([id]);
      let ans: number=0;
      courses.forEach((course)=>{
        if(cart.includes(course.id) || course.id==id){
          ans=ans+(course.actual-Math.floor((course.actual*course.discount)/100));;
        }
      })
      setPrice(ans);
      
      console.log("Added your first course book", cart);
    }
    else{
      setCart(cart => [...cart, id]);
      let ans: number=0;
      courses.forEach((course)=>{
        if(cart.includes(course.id) || course.id==id){
          ans=ans+(course.actual-Math.floor((course.actual*course.discount)/100));
        }
      })
      setPrice(ans);
      console.log(cart);
    }
  }

  // Function for searching courses based on tags in the Dashboard
  const onSearch = (text: string) => {
    if(text=="" || text=="Search here"){
      setSearchBool(false);
      setSearchCourses(sortBool? sortCourses.slice() : courses.slice());
      console.log(courses);
    }
    else{
      setSearchBool(true);
      setPriority(1);
      setBaseAddress(0);
      let newSearchData: courseDetails[] = (sortBool? sortCourses.slice() : courses.slice());
      newSearchData = newSearchData.filter((course) => course.tags.includes(text));
      setSearchCourses(newSearchData);
      console.log(searchBool, newSearchData, courses);
    }
  }


  // For sorting the data based on price
  const onSort = (sortValue: string) => {
    if(sortValue=="price"){
      setBaseAddress(0);
      setSortBool(false);
      setSortCourses(searchBool? searchCourses.slice() : courses.slice());
      console.log(sortBool, sortCourses);
    }
    else if(sortValue=="LtoH"){
      setSortBool(true);
      setPriority(2);
      setBaseAddress(0);
      let newSortData=(searchBool? searchCourses.slice() : courses.slice());
      newSortData = newSortData.sort((a,b) => (a.actual>b.actual)? 1: -1);
      setSortCourses(newSortData);
      console.log(sortBool, newSortData);
    }
    else{
      setSortBool(true);
      setPriority(2);
      setBaseAddress(0);
      let newSortData=(searchBool? searchCourses.slice() : courses.slice());
      newSortData = newSortData.sort((a,b) => (a.actual>b.actual)? -1: 1);
      setSortCourses(newSortData);
      console.log(sortBool, newSortData, courses);
    }
  }

  const deleteItem = (id: number) => {
    if(cart.length>0){
      const newData: number[] = cart.filter((cartId) => cartId!=id);
      setCart(newData);
      if(newData.length>0){
        let ans: number=0;
        courses.forEach((course)=>{
        if(newData.includes(course.id)){
          ans=ans+(course.actual-Math.floor((course.actual*course.discount)/100));;
        }
        })
        setPrice(ans);
      }
      else{
        setPrice(0);
      }
    }
  }

  const handleCheckout = () => {
    setDisp(true);
    setCart([]);
    setPrice(0);
    setBaseAddress(0);
  }

  const confirmOrder = () => {
    setDisp(false);
    setPath("Dashboard");
  }

  useEffect(() => {
    console.log("Hi");
  }, [searchCourses, sortCourses])

  return (
    <div className="App">
      <Header changePath={setPath}/>
      {(path=="Dashboard")? <Dashboard 
                data={sortBool? (searchBool? ((priority==1)? searchCourses: sortCourses): sortCourses) : (searchBool? searchCourses: courses)} 
                onAddCart={addCart} 
                cartId={cart} 
                price={price}
                onSearch={onSearch}
                onSort={onSort}
                origData={courses}
                onPageChange={changePage}
                baseAddress={baseAddress}
                changePath={setPath}
                /> : <Cart 
                    data={courses.filter((course) => cart.includes(course.id))} 
                    price={price}
                    handleDelete={deleteItem}
                    display={disp}
                    changeDisplay={setDisp}
                    handleCheckout={handleCheckout}
                    confirmOrder={confirmOrder}
                    />}
    </div>
  );
}


export default App;
