import DashboardHead from "./components/DashboardHead"
import './Dashboard.css'
import DashboardMain from "./DashboardMain"
import { courseDetails } from './Data'

export interface DashboardProps {
    data: courseDetails[]
    onAddCart: (id: number| never) => void,
    cartId: number[],
    price: number,
    onSearch: (text: string) => void,
    onSort: (text: string) => void,
    origData: courseDetails[],
    onPageChange: (x: number)=>void,
    baseAddress: number,
    changePath: (x: string) => void
}
 
const Dashboard: React.FC<DashboardProps> = ({ data, onAddCart, cartId, price, onSearch, onSort, origData, onPageChange, baseAddress, changePath}) => {
    return (  
        <div className="Dashboard">
            <DashboardHead heading="Discover courses on react"/>
            <DashboardMain 
                data={data} 
                onAddCart={onAddCart} 
                cartId={cartId} 
                price={price}
                onSearch={onSearch}
                onSort={onSort}
                origData={origData}
                onPageChange={onPageChange}
                baseAddress={baseAddress}
                changePath={changePath}
            />
        </div>
    );
}
 
export default Dashboard;