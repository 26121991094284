import Cart from "./Cart";
import { courseDetails } from "./Data";

export interface DashboardCartCardProps {
    card: courseDetails,
}
 
const DashboardCartCard: React.FC<DashboardCartCardProps> = ({card}) => {
    return (  
        <article className="dashboardCartCard" style={{borderBottom: "2px solid #F2F2F2"}}>
            <div className="greyBlock"></div>
            <p>{card.title}</p>
            <p><b>Rs: {card.actual-Math.floor((card.actual*card.discount)/100)}</b></p>
        </article>
    );
}
 
export default DashboardCartCard;