import { courseDetails } from "./Data";
import CartItems  from './CartItems';
import CheckoutModal from './CheckoutModal'

export interface CartMainProps {
    data: courseDetails[],
    price: number,
    handleDelete: (id: number) => void,
    display: boolean,
    changeDisplay: (x: boolean) => void,
    handleCheckout: () => void,
    confirmOrder: () => void
}
 
const CartMain: React.FC<CartMainProps> = ({ data, price, handleDelete, display, changeDisplay, handleCheckout, confirmOrder }) => {
    return (  
        <article className="CartMain">
            <section>
                {
                    data.map((courseinCart)=> 
                        (<CartItems course={courseinCart} handleDelete={handleDelete}/>)   
                    )
                }
                <div>

                </div>
            </section>
            <section className="CheckoutSection">
                <p>Total Amount</p>
                <h2>Rs {price}/-</h2>
                <p className="checkOut" onClick={() => handleCheckout()}>CHECKOUT</p>
            </section>
            <CheckoutModal display={display} confirmOrder={confirmOrder} changeDisplay={changeDisplay}/>
        </article>
    );
}
 
export default CartMain;