import { courseDetails } from "./Data";
import star from './images/Path 28.svg';
import arrow from './images/Path 20.svg';
import Tags from "./Tags";

export interface CourseCardProps {
    courseBook: courseDetails
    onAddCart: (id: number | never) => void
}
 
const CourseCard: React.FC<CourseCardProps> = ({courseBook, onAddCart}) => {
    return (  
        <article className="courseCard">
            <div className="greyBlock"></div>
            <div>
                <p>{courseBook.title}</p>
                <div className="tagStyling">
                {
                    courseBook.tags.map((tag) => (
                        <Tags tagName={tag} />
                    ))
                }
                </div>
            </div>
            <p>{courseBook.author}</p>
            <img src={star} className="starImg"/>
            <p><b>{courseBook.actual-Math.floor((courseBook.actual*courseBook.discount)/100)}</b></p>
            <p style={{textDecoration: "line-through"}}>{courseBook.actual}</p>
            <p onClick={() => onAddCart(courseBook.id)} className="addToCart">ADD TO CART</p>
            <img src={arrow} className="arrowImg"/>
        </article>
    );
}
 
export default CourseCard;