import { courseDetails } from "./Data";
import bin from './images/Path 22.svg'

export interface CartItemsProps {
    course: courseDetails,
    handleDelete: (id: number) => void 
}
 
const CartItems: React.FC<CartItemsProps> = ({course, handleDelete}) => {
    return (  
        <article className="cartItem">
            <div className="greyBlock"></div>
            <div>
                <h2>{course.title}</h2>
                <p>{course.author}</p>
            </div>
            <p style={{color: "#1873CE"}}>Move to Wishlist</p>
            <p>{course.actual-Math.floor((course.actual*course.discount)/100)}</p>
            <img src={bin} className="Bin" onClick={() => handleDelete(course.id)} />
        </article>
    );
}
 
export default CartItems;