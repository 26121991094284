import DashboardCartCard from "./DashboardCartCard";
import { courseDetails } from "./Data";

export interface DashboardCartProps {
    data: courseDetails[],
    price: number,
    changePath: (x: string) => void
}
 
const DashboardCart: React.FC<DashboardCartProps> = ({data, price, changePath}) => {

    
    return (  
        <aside className="dashboard_cart">
            <p className="dash_cart_head" style={{borderBottom: "2px solid #F2F2F2"}}>YOUR CART DETAILS</p>
            {data.map((card) => {
                return (
                <DashboardCartCard key={card.id} card={card}/>
                );
            })}
            <article className="PriceArea">
                <p>Total Cart Value</p>
                <span><b>Rs {price}/-</b></span>
                <span className="checkout" onClick={() => changePath("cart")}>GOTO CHECKOUT</span>
            </article>
        </aside>
    );
}
 
export default DashboardCart;