import CheckoutConfirm from "./CheckoutConfirm";
import './CheckoutModal.css'

export interface CheckoutModalProps {
    display: boolean,
    confirmOrder: () => void
    changeDisplay: (x: boolean) => void
}
 
const CheckoutModal: React.FC<CheckoutModalProps> = ({display, confirmOrder, changeDisplay }) => {
    const showHideClassName: string = display? "modal show-block" : "modal show-none";

    return (  
        <div className={showHideClassName}>
            <section className="modal-main">
                <CheckoutConfirm confirmOrder={confirmOrder} changeDisplay={changeDisplay}/>
            </section>
        </div>
    );
}
 
export default CheckoutModal;