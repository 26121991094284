import React from "react";
import DashboardCart from "./DashboardCart";
import DashboardList from "./DashboardList";
import DashboardSearch from "./DashboardSearch"
import { courseDetails } from './Data';

export interface DashboardMainProps {
    data: courseDetails[],
    onAddCart: (id: number | never) => void,
    cartId: number[],
    price: number,
    onSearch: (text: string) => void,
    onSort: (text: string) => void,
    origData: courseDetails[],
    onPageChange: (x: number) => void,
    baseAddress: number,
    changePath: (x: string) => void
}
 
const DashboardMain: React.FC<DashboardMainProps> = ({ data, onAddCart, cartId, price, onSearch, onSort, origData, onPageChange, baseAddress, changePath }) => {
    return (  
        <article className="DashboardMain">
            <DashboardSearch onSearch={onSearch} onSort={onSort}/>
            <DashboardList courseBooks={data} onAddCart={onAddCart} onPageChange={onPageChange} baseAddress={baseAddress}/>
            <DashboardCart data={origData.filter((card) => cartId.includes(card.id))} price={price} changePath={changePath}/>
        </article>
    );
}
 
export default DashboardMain;