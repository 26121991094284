import DashboardHead from "./components/DashboardHead";
import './Cart.css';
import { courseDetails } from "./Data";
import CartMain from "./CartMain";

export interface CartProps {
    data: courseDetails[],
    price: number,
    handleDelete: (id: number) => void
    display: boolean,
    changeDisplay: (x: boolean) => void,
    handleCheckout: () => void,
    confirmOrder: () => void
}
 
const Cart: React.FC<CartProps> = ({ data, price, handleDelete, display, changeDisplay, handleCheckout, confirmOrder}) => {
    return (  
        <div className="Cart">
            <DashboardHead heading="Shopping cart"/>
            <p>{data.length} courses in shopping cart</p>
            <CartMain 
                data = {data} 
                price={price} 
                handleDelete={handleDelete} 
                display={display} 
                changeDisplay={changeDisplay}
                handleCheckout={handleCheckout}
                confirmOrder={confirmOrder}/>
        </div>
    );
}
 
export default Cart;