import tick from './images/noun_tick_1611480.svg';
import './CheckoutConfirm.css'

export interface CheckoutConfirmProps {
    confirmOrder: () => void,
    changeDisplay: (x: boolean) => void
}
 
const CheckoutConfirm: React.FC<CheckoutConfirmProps> = ({ confirmOrder, changeDisplay }) => {
    return (  
        <article className="CheckoutConfirm">
            <p className="close" onClick={() => changeDisplay(false)}>X</p>
            <div className="checkContent">
                <img src={tick} alt="tick"/>
                <p><b>You have successfully placed your order</b></p>
            </div>
            <p className="ConfirmOrder" onClick={confirmOrder}>OK</p>
        </article>
    );
}
 
export default CheckoutConfirm;