export interface TagsProps {
    tagName: string
}
 
const Tags: React.FC<TagsProps> = ({tagName}) => {
    return (  
        <div className="Tags">{tagName}</div>
    );
}
 
export default Tags;