import mask from '../images/Mask Group 1/Mask Group 1.png'

export interface DashboardHeadProps {
    heading: string
}
 
const DashboardHead: React.FC<DashboardHeadProps> = ({ heading }) => {
    return (  
        <header className="DashboardHead">
            <p title="heading">{ heading }</p>
            <img src={mask} alt="Mask" className="MaskImg"/>
        </header>
    );
}
 
export default DashboardHead;