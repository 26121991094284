import CourseCard from "./courseCard";
import { courseDetails } from "./Data";
import lessthan from './images/Path 59.svg';
import greaterthan from './images/Path 58.svg';

export interface DashboardListProps {
    courseBooks: courseDetails[],
    onAddCart: (id: number | never) => void
    onPageChange: (x: number) => void 
    baseAddress: number
}
 
const DashboardList: React.FC<DashboardListProps> = ({ courseBooks, onAddCart, onPageChange, baseAddress }) => {
    let pageLength: number = 2;
    const pagedData = courseBooks.slice(baseAddress, baseAddress+pageLength);
    return (  
        <article>
            {pagedData.map((courseBook) => (
                <CourseCard courseBook={courseBook} onAddCart={onAddCart} key={courseBook.id}/>
            ))}
            <img src={lessthan} onClick={() => onPageChange(-pageLength)} className="pageAdjust"/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={greaterthan} onClick={() => {
                (baseAddress+pageLength<courseBooks.length)? onPageChange(pageLength): console.log("last page");
                }} className="pageAdjust"/>
            
        </article>
    );
}
 
export default DashboardList;