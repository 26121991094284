export interface courseDetails {
    id: number
    title: string,
    author: string,
    actual: number,
    discount: number,
    tags: string[]
}

const data: courseDetails[] = [
    {id: 0, title: "Responsive", author: "John", actual: 120, discount: 10, tags: ["React", "Reactjs"]},
    {id: 1, title: "Lorum", author: "Ipsum", actual: 720, discount: 0, tags: ["Lorum Ipsum", "Reactjs"]},
    {id: 2, title: "Sarfariz", author: "Idalut", actual: 300, discount: 2, tags: ["React", "Hooks"]},
    {id: 3, title: "Sarfarz", author: "Idalut", actual: 300, discount: 2, tags: ["React", "Hooks"]},
    {id: 4, title: "Saariz", author: "Idalut", actual: 300, discount: 2, tags: ["React", "Hooks"]},
    {id: 5, title: "Sarfar", author: "Idalut", actual: 300, discount: 2, tags: ["React", "Hooks"]},
    {id: 6, title: "Sarriiiiz", author: "Idalut", actual: 300, discount: 2, tags: ["React", "Hooks"]}
]

export {data}