import { useState } from 'react';
import searchimg from './images/Group 35.svg';
export interface DashboardSearchProps {
    onSearch: (text: string) => void
    onSort: (text: string) => void
}
 
const DashboardSearch: React.FC<DashboardSearchProps> = ({ onSearch, onSort }) => {
    const [search, setSearch] = useState<string>("Search here");

    return (  
        <section className="dashboardSearch">
            <section className="SortArea">
                <p title="allcourses">All Courses</p>
                <article className="selectdiv">
                    <label>
                        <select onChange={(e) => {
                                            onSort(e.currentTarget.value)
                                            console.log(e.currentTarget.value);
                                            }}>
                            <option value="price" selected> Course price </option>
                            <option value="LtoH">Low to High</option>
                            <option value="HtoL">High to Low</option>
                        </select>
                    </label>
                </article>
            </section>
            <article className="searchBox">
                <input type="text" title="searchfield" value={search} className="searchBoxInput" onInput={(e) => setSearch(e.currentTarget.value)}/>
                <img src={searchimg} alt="search" className="searchimg" onClick={() => {
                    onSearch(search);
                }}/>
            </article>
        </section>
    );
}
 
export default DashboardSearch;